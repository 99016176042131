<template>
  <v-app>
    <v-navigation-drawer
      v-if="login.auth"
      v-model="drawer"
      absolute
      temporary
      app
      clipped
    >
      <Menu />
    </v-navigation-drawer>

    <v-card v-if="login.auth">
      <v-toolbar dense color="grey darken-4" class="toolbar white--text">
        <v-app-bar-nav-icon class="white--text" @click="drawer = true" />
        <div class="d-flex align-center">
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          {{ isIdle }}
        </div>
        <v-spacer />

        <v-tooltip v-if="login.permissions.insureds_migration.read" bottom>
          <template v-slot:activator="{ on }">
            <v-badge
              overlap
              :color="
                (insurance_migrations.length > 0 ? 'deep-orange' : '') +
                ' darken-1'
              "
              :content="insurance_migrations.length"
              :dot="insurance_migrations.length == 0"
            >
              <v-btn
                v-on="on"
                fab
                dark
                x-small
                class="ml-1"
                :color="
                  (insurance_migrations.length > 0 ? 'deep-orange' : '') +
                  ' darken-1'
                "
                :loading="insurance_migrations_ldg"
                @click.prevent="insuranceMigrationsDlg"
              >
                <v-icon> mdi-bell </v-icon>
              </v-btn>
            </v-badge>
          </template>
          <span v-text="'Migración de asegurados'" />
        </v-tooltip>

        <v-btn v-if="login.auth" icon dark @click.prevent="logoutMessage">
          <v-icon> mdi-logout-variant </v-icon>
        </v-btn>
      </v-toolbar>
    </v-card>

    <v-main>
      <v-container>
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>

    <v-footer app padless>
      <v-row dense>
        <v-col cols="6">
          <small
            v-if="login.auth"
            v-text="login.email"
            class="pl-1 font-weight-bold"
          />
        </v-col>
        <v-col cols="6" class="text-right">
          <small v-text="version" class="pr-1" />
        </v-col>
      </v-row>
    </v-footer>

    <v-dialog
      v-model="insurance_migrations_dlg"
      scrollable
      persistent
      max-width="1000"
    >
      <v-card tile>
        <v-toolbar dark dense>
          <v-toolbar-title> MIGRACIÓN DE ASEGURADOS </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn icon dark @click="insurance_migrations_dlg = false">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text v-if="insurance_migrations">
          <v-row class="pt-3">
            <v-col cols="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th v-text="'Contratante'" />
                      <th v-text="'Fecha'" />
                      <th v-text="'Usuario'" />
                      <th />
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in insurance_migrations" :key="i">
                      <td v-text="item.contractor.name" />
                      <td v-text="item.created_at" />
                      <td v-text="item.created_by.email" />
                      <td>
                        <v-btn
                          v-if="login.permissions.insureds_migration.create"
                          icon
                          x-small
                          class="ml-1"
                          :color="
                            (!item.seen ? 'deep-orange' : '') + ' darken-1'
                          "
                          @click.prevent="migrationView(item)"
                        >
                          <v-icon> mdi-bell </v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import Axios from "axios";
import { URL_API, headersToken, URL } from "./control";
import { logOut } from "./requests/authRequest";
import Menu from "./components/Menu.vue";

export default {
  components: {
    Menu,
  },
  data() {
    return {
      title: "Sinergia Médica",
      drawer: false,
      login: this.$store.getters.getLogin,
      version: "v1.24.12.04",
      url: URL,
      insurance_migrations: [],
      insurance_migrations_ldg: true,
      insurance_migrations_dlg: false,
      insurance_migrations_dlg_ldg: false,
    };
  },
  methods: {
    logoutMessage() {
      this.$swal
        .fire({
          showCancelButton: true,
          cancelButtonText: "No",
          confirmButtonText: "Sí",
          icon: "warning",
          text: `¿Confirma cerrar la sesión?`,
        })
        .then((response) => {
          if (response.isConfirmed) {
            this.logout();
          }
        });
    },
    logout() {
      logOut(this.login.token).then((response) => {
        this.$store.dispatch("logOutAction");

        if (response.success) {
          window.location.assign("/login");
        } else {
          console.log(response.message);

          this.$swal.fire({
            showConfirmButton: false,
            icon: response.success ? "success" : "error",
            text: response.message,
            timer: 2000,
          });
        }
      });
    },
    insuranceMigrations() {
      this.insurance_migrations_ldg = true;
      this.insurance_migrations = [];

      Axios.get(
        URL_API + "/insurance/migrations/notifications",
        headersToken(this.login.token)
      ).then((resp) => {
        this.insurance_migrations = resp.data.data;
        this.insurance_migrations_ldg = false;
      });
    },
    insuranceMigrationsDlg() {
      this.insurance_migrations_dlg_ldg = false;
      this.insurance_migrations_dlg = true;
    },
    migrationView(item) {
      Axios.post(
        URL_API + "/insurance/migrations/notifications/seen",
        item,
        headersToken(this.login.token)
      ).then((rsp) => {
        if (rsp.data.success) {
          this.$router.push({
            name: "asegurados.migracion",
            params: { contractor_id: item.contractor_id },
          });
          this.insuranceMigrations();
          this.insurance_migrations_dlg = false;
        }

        this.insurance_migrations_dlg_ldg = false;
      });
    },
  },
  mounted() {
    this.url = this.url.split("/");
    this.url = this.url[this.url.length - 2];
    this.version = `${this.url} | ${this.version}`;

    if (this.login.auth) {
      this.insuranceMigrations();
    }
  },
  computed: {
    isIdle() {
      if (
        this.login.auth &&
        this.$store.state.idleVue &&
        this.$store.state.idleVue.isIdle
      ) {
        this.logout();
      }
    },
  },
};
</script>

<style>
.toolbar {
  border-top-left-radius: 0px !important;
  border-top-right-radius: 0px !important;
}

.v-main {
  background-color: #f8f9fa;
}

.swal2-html-container,
.swal2-styled {
  font-family: "Roboto" !important;
}
</style>
